.navbar-top {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.4);
  background-color: #e5383b !important;
  font-family: "Open Sans", sans-serif;

  color: white !important;
  /* position: relative; */
  border-bottom-left-radius: 20%;
  border-bottom-right-radius: 20%;
  padding-bottom: 1rem;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  color: white;
}

.navbar-brand,
.menu-item {
  color: white !important;
  font-family: "Open Sans", sans-serif;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
}

button.nav-burger.navbar-toggler.collapsed {
  border: none !important;
}

button:focus {
  outline: none !important;
  border: none !important;
}

.menut-item:first-child {
  margin-top: 2rem;
}
