.App {
  text-align: center;
  overflow: auto;
}

.background-video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -2;
  opacity: 0.6;
}
