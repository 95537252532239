.search-container {
  margin-top: 5rem;
  margin-bottom: 1rem;

  width: 80%;
  border-radius: none !important;
}

.input-text {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: none !important;
  width: 5rem !important;
  font-size: 1.5rem !important;
  text-align: center;
}

.form-control,
.form-control:focus {
  box-shadow: none !important;
  background-color: white !important;
  border-radius: none !important;
  letter-spacing: 0.2rem;
  outline: none !important;
  border: none !important;
  text-align: center;
  height: 4rem !important;
  font-size: 1.4rem !important;
  font-family: "Open Sans", sans-serif;
}

::placeholder {
  color: black !important;
  text-align: center;
  font-size: 1.2rem;
}

#inputGroup-sizing-lg {
  border-radius: none !important;
}

.check-status-text {
  color: white;
  font-family: "Open Sans", sans-serif;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  font-size: 1.5rem;
}

.direction-status-text {
  color: white;
  font-family: "Open Sans", sans-serif;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  font-size: 1rem;
}
.form-row {
  margin: 1rem 0;
}

::placeholder {
  color: grey !important;
  opacity: 0.5 !important;
  letter-spacing: 0.5rem;
}

.warning-row {
  margin-bottom: none !important;
}

.warning-txt {
  color: yellow;
  font-family: "Open Sans", sans-serif;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  font-size: 1rem;
}

.check-button {
  margin-top: 1rem;
  font-family: "Open Sans", sans-serif;
  width: 50%;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  /* box-shadow: none !important; */
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px !important;
  font-size: 1rem;
  background-color: #208b3a;
  outline: none !important;

  border: none !important;
  border-color: transparent !important;
}

.check-button:focus,
.check-button:active,
.check-button:hover {
  border: none !important;
  outline: none !important;
  background-color: #208b3a !important;
  /* box-shadow: none !important; */
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px !important;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  border-color: transparent !important;
  color: white;
  width: 50%;
  outline: none !important;
  border: none !important;
}

.check-button:disabled {
  margin-top: 1rem;
  font-family: "Open Sans", sans-serif;
  width: 50%;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  /* box-shadow: none !important; */
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px !important;
  font-size: 1rem;
  background-color: grey;
  outline: none !important;
  opacity: 0.6;

  border: none !important;
  border-color: transparent !important;
}

.progress-bar-round {
  font-family: "Open Sans", sans-serif;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  /* box-shadow: none !important; */

  font-size: 1rem;
  transition: 1s;
}

.status-raw {
  margin-top: 1rem;
  font-family: "Open Sans", sans-serif;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  color: white;
}

.status-raw-complete {
  margin-top: 1rem;
  font-family: "Open Sans", sans-serif;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  color: yellow;
  background-color: #e5383b;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px !important;
}

.stat-icon {
  margin-left: 0.3rem;
}

.modal-header {
  background-color: #e5383b;
  border-style: none !important;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  color: #e9c46a;
}

.modal-body {
  background-color: #e5383b;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  color: #e9c46a;
}

.warning-icon {
  font-size: 1rem;
}
