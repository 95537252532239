.about-container {
  margin-top: 5rem;

  font-family: "Open Sans", sans-serif !important;
  color: white;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  margin-bottom: 1rem !important;
  padding-bottom: 1rem;
}

.speech-div {
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.4);
  border-radius: 20px;
  margin: 1rem;
  padding: 0.5rem;
  color: black;
  text-shadow: none !important;
}
