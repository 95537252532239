.footer {
  background-color: #e5383b !important;
  font-family: "Open Sans", sans-serif;
  color: white !important;
  /* position: relative; */
  border-top-right-radius: 20%;
  border-top-left-radius: 20%;
  /* padding-bottom: 1rem !important;
 
  margin-top: 1rem;

  padding: 1rem !important; */
  height: 2.5rem;

  padding: 1rem !important;
}

.navbar-icons-container {
  padding-top: 0 !important;

  margin-right: 0.6rem;
  font-size: 0.8rem;
  opacity: 0.7;
  text-shadow: none !important;
}
