.package-container {
  font-family: "Open Sans", sans-serif;
  margin-top: 2rem;

  /* text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5); */
  color: white;
  opacity: 0.5;
  width: 100%;
}

.package-container-checking {
  font-family: "Open Sans", sans-serif;
  margin-top: 1rem;
  margin-bottom: 1.5rem;

  /* text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5); */
  color: white;
  opacity: 1;
  width: 100%;
}

.item-row {
  margin-bottom: 1rem;
}

.list-item {
  display: block !important;
  background-color: white;
  font-size: 1rem;
}

.list-item-checking {
  background-color: #e9c46a;
  font-size: 1rem;
}

.status-list {
  margin: 0;
  padding: 0;
  display: block !important;
}
