.hero-container {
  font-family: "Open Sans", sans-serif !important;
  color: white;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  margin-bottom: 1rem !important;
  padding-bottom: 10rem;
}

.data-row {
  letter-spacing: 0.5rem;
}

.num-label {
  font-size: 2rem;
  color: #e9c46a;
}

.txt-label {
  font-size: 0.8rem;
}
